<template>
  <div class="depense-card">
    <div class="depense-header">
      <div class="depense-title">{{ depense.motif }}</div>
      <div class="depense-date">
        <span v-if="depense.cite !== null">{{ depense.cite.nomCite }}</span>
        <span v-if="depense.batiment !== null">{{
          depense.batiment.nomBatiment
        }}</span>
        <span v-if="depense.logement !== null">{{
          depense.logement.refLogement
        }}</span>
        <span>{{ $dayjs(depense.dateDepense).format("DD MMMM YYYY") }}</span>
      </div>
    </div>
    <div class="depense-main">
      <span v-if="depense.observation">
        {{ depense.observation }}
      </span>
      <div v-else class="desc">
        <i class="ik ik-file-text"></i>
        <p>Pas d'observation particulière</p>
      </div>
    </div>
    <div class="depense-footer">
      <div class="depense-montant">
        <span>{{depense.montant}} Fcfa</span>
      </div>
      <div class="depense-controller">
        <div class="control" title="Editer la dépense">
          <i class="ik ik-edit"></i>
        </div>
        <div class="control" title="Supprimer la dépense" @click="remove()">
          <i class="ik ik-trash"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    depense: { type: Object, required: true },
  },
  methods: {
    remove() {
      App.confirm(
        `Voullez vous vraiment supprimer la dépense " <b>${this.depense.motif}</b> " ?`,
        {
          confirm: () => {
            axios
              .delete(`depenses/${this.depense.idDepense}`)
              .then((response) => {
                this.$emit("deleted", this.depense.idDepense);
                return App.notifySuccess(response.message);
              })
              .catch((error) => {
                return App.notifyError(error.message);
              });
          },
        }
      );
    },
  },
};
</script>

<style scoped>
.depense-card {
  padding: 15px 15px 0;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: auto;
  height: auto;
  min-height: 20rem;
  border-radius: 8px;
  box-shadow: 0 5px 12px rgba(0, 0, 0, 0.08);
}
.depense-header {
  padding: 0 0 10px;
  width: 100%;
  height: auto;
  min-height: 5.5rem;
  border-bottom: 1px solid #eee;
}
.depense-title {
  font-weight: 600;
  font-size: 1.1rem;
}
.depense-title::first-letter {
    text-transform: capitalize;
}
.depense-date {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  color: rgb(88, 88, 88);
}
.depense-date span::first-letter{
    text-transform: capitalize;
}
.depense-main {
  padding: 15px 0 0;
  width: 100%;
  height: calc(100% - 5.5rem - 50px);
  text-align: justify;
  font-weight: 500;
  line-height: 1.5rem;
  font-size: 0.92rem;
  color: #303030;
}
.depense-main::first-letter {
  text-transform: uppercase;
}
.desc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
.desc i {
  font-size: 1.2rem;
}
.desc p {
  margin-top: 10px;
  font-size: 0.92rem;
  font-weight: 500;
  text-align: center;
}
.depense-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  border-top: 1px solid #eee;
}
.depense-montant {
  font-weight: 600;
  font-size: 0.95rem;
  /* color: #f5365c; */
}
.depense-controller {
  display: flex;
  align-items: center;
  height: 100%;
  width: auto;
}
.depense-controller .control {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  width: 25px;
  border-radius: 8px;
  cursor: pointer;
  /* background: teal; */
}
.depense-controller .control:nth-child(1) {
  margin-right: 10px;
}
.depense-controller .control i {
  font-size: 1.2rem;
}
</style>