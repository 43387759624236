<template>
  <div class="create-cite" @click="close()">
    <div class="cite-form" @click.stop="">
      <div class="error-popup" v-show="incompleteData">
        <i class="ik ik-alert-triangle"></i>
        <span>
          {{ customErrorMsg }}
        </span>
      </div>
      <div class="header-form">
        <span> Ajouter une dépense </span>
        <div class="close-icon">
          <i class="ik ik-x clos" @click="close()"></i>
        </div>
      </div>
      <div class="main-form">
        <div class="main-left">
          <div class="input-item">
            <span>Coût de la recharge*</span>
            <input
              type="number"
              placeholder="Ex: 5000"
              v-model="depense.montant"
            />
          </div>
          <div class="input-item">
            <span>Date*</span>
            <DatePicker
              placeholder="Selectionnez une date"
              format="dddd, DD MMMM YYYY"
              valueType="YYYY-MM-DD"
              class="w-100"
              :clearable="false"
              v-model="depense.date"
            />
          </div>
          <div class="input-item">
            <span>Motif*</span>
            <input
              type="text"
              placeholder="ex: refaire la cuisine"
              v-model="depense.motif"
            />
          </div>
          <div class="input-item">
            <span>Entité concernéé</span>
            <div class="entity-concern">
              <label>
                <input
                  type="radio"
                  name=""
                  id=""
                  value="cite"
                  v-model="commandeAction"
                />
                <span>Cité</span>
              </label>
              <label>
                <input
                  type="radio"
                  name=""
                  id=""
                  value="batiment"
                  v-model="commandeAction"
                />
                <span>Batiment</span>
              </label>
              <label>
                <input
                  type="radio"
                  name=""
                  id=""
                  value="logement"
                  v-model="commandeAction"
                />
                <span>Logement</span>
              </label>
            </div>
          </div>
        </div>
        <div class="main-right">
          <div class="input-item">
            <span>Responsable*</span>
            <input
              type="text"
              placeholder="Un commentaire, une note..."
              v-model="depense.responsable"
            />
          </div>
          <div class="input-item">
            <span>Description</span>
            <textarea
              name=""
              id=""
              cols="30"
              rows="5"
              placeholder="Un commentaire, une note..."
              v-model="depense.observation"
            ></textarea>
          </div>
          <div class="input-item" v-if="commandeAction !== ''">
            <span v-if="commandeAction == 'cite'">Cité concernée</span>
            <span v-if="commandeAction == 'batiment'">Batiment concerné</span>
            <span v-if="commandeAction == 'logement'">Logement concerné</span>
            <select v-if="commandeAction == 'cite'" v-model="idEntity">
              <option
                v-for="(entity, cle) in entityData"
                :key="cle"
                :value="entity.idCite"
              >
                {{ entity.nomCite }}
              </option>
            </select>
            <select v-if="commandeAction == 'batiment'" v-model="idEntity">
              <option
                v-for="(entity, cle) in entityData"
                :key="cle"
                :value="entity.idBatiment"
              >
                {{ entity.nomBatiment }}
              </option>
            </select>
            <select v-if="commandeAction == 'logement'" v-model="idEntity">
              <option
                v-for="(entity, cle) in entityData"
                :key="cle"
                :value="entity.idLogement"
              >
                {{ entity.refLogement }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="footer-form">
        <div class="cancel" @click="close()">
          <OutlinedDarkBtn buttonTitle="Annuler" />
        </div>
        <div class="validate" @click="saveDepense()">
          <!-- <b-spinner small /> -->
          <FullPinkBtn buttonTitle="Ajouter la dépense" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const php = require("phpjs");
import FullPinkBtn from "../atomic/fullPinkBtn.vue";
import OutlinedDarkBtn from "../atomic/outlinedDarkBtn.vue";
import DatePicker from "vue2-datepicker";
export default {
  components: { FullPinkBtn, OutlinedDarkBtn, DatePicker },
  props: {},
  data() {
    return {
      photo: "",
      commandeAction: "",
      entityData: null,
      listEntity: null,
      idEntity: "",
      depense: {
        montant: "",
        date: "",
        motif: "",
        responsable: "",
        observation: "",
      },
      id: "",
      incompleteData: false,
      customErrorMsg: `Données incomplètes`,
      cites: [],
      batiments: [],
      logements: [],
    };
  },
  watch: {
    commandeAction() {
      switch (this.commandeAction) {
        case "logement":
          //   this.idEntity = this.entityData.idLogement;

          break;
        case "cite":
          //   this.idEntity = this.entityData.idCite;

          break;
        case "batiment":
          //   this.idEntity = this.entityData.idBatiment;

          break;

        default:
          break;
      }
      this.setAction();
    },
  },
  methods: {
    onFile(e) {
      const files = e.target.files;
      if (!files.length) return;
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => (this.modal.photoRecu = reader.result);
      console.log(this.modal.photoRecu);
    },
    close() {
      this.$emit("close");
    },
    async setAction() {
      switch (this.commandeAction) {
        case "cite":
          try {
            this.entityData = await axios
              .get("cites")
              .then((response) => response.result);
            console.log(this.entityData);
          } catch (error) {
            this.customErrorMsg = error;
            this.incompleteData = true;
            setTimeout(() => {
              this.incompleteData = false;
            }, 2000);
          }
          break;
        case "batiment":
          try {
            this.entityData = await axios
              .get("batiments")
              .then((response) => response.result);
            console.log(this.entityData);
          } catch (error) {
            this.customErrorMsg = error;
            this.incompleteData = true;
            setTimeout(() => {
              this.incompleteData = false;
            }, 2000);
          }
          break;
        case "logement":
          try {
            this.entityData = await axios
              .get("logements")
              .then((response) => response.result);
            console.log(this.entityData);
          } catch (error) {
            this.customErrorMsg = error;
            this.incompleteData = true;
            setTimeout(() => {
              this.incompleteData = false;
            }, 2000);
          }
          break;

        default:
          break;
      }
    },
    //validation formulaire d'ajout/modification d'une dépense
    saveDepense() {
      if (
        php.empty(this.depense.montant) ||
        php.empty(this.depense.motif) ||
        php.empty(this.id)
      ) {
        this.customErrorMsg =
          "Un montant, un motif et l'entité concernée par la dépense sont obligatoires";
        this.incompleteData = true;
        setTimeout(() => {
          this.incompleteData = false;
        }, 2000);
      }
      if (this.depense.montant == "") {
        this.customErrorMsg = "Vous devez spécifier un montant";
        this.incompleteData = true;
        setTimeout(() => {
          this.incompleteData = false;
        }, 2000);
      }
      if (this.depense.motif == "") {
        this.customErrorMsg = "Vous devez spécifier un motif";
        this.incompleteData = true;
        setTimeout(() => {
          this.incompleteData = false;
        }, 2000);
      }
      if (this.id == "") {
        this.customErrorMsg =
          "Vous devez spécifier l'entité concernée par la dépense";
        this.incompleteData = true;
        setTimeout(() => {
          this.incompleteData = false;
        }, 2000);
      }
      
      if (this.commandeAction == "logement") {
            console.log(this.idEntity);

        axios
          .post("/logements/" + this.idEntity + "/depenses", this.depense)
          .then((response) => {
            this.showOverlayP = false;
            this.$emit("update", response.result);
            return App.notifySuccess(response.message);
          })
          .catch((error) => {
            this.customErrorMsg = error;
            this.incompleteData = true;
            setTimeout(() => {
              this.incompleteData = false;
            }, 2000);
          });
      }
      if (this.commandeAction == "cite") {
        axios
          .post("/cites/" + this.idEntity + "/depenses", this.depense)
          .then((response) => {
            this.$emit("update", response.result);
            return App.notifySuccess(response.message);
          })
          .catch((error) => {
            this.customErrorMsg = error;
            this.incompleteData = true;
            setTimeout(() => {
              this.incompleteData = false;
            }, 2000);
          });
      }
      if (this.commandeAction == "batiment") {
        axios
          .post("/batiments/" + this.idEntity + "/depenses", this.depense)
          .then((response) => {
            this.showOverlayP = false;
            this.$emit("update", response.result);
            return App.notifySuccess(response.message);
          })
          .catch((error) => {
            this.customErrorMsg = error;
            this.incompleteData = true;
            setTimeout(() => {
              this.incompleteData = false;
            }, 2000);
          });
      }
    },
  },
  mounted() {
    let googleScript = document.createElement("script");
    googleScript.setAttribute(
      "src",
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyB4wnGV-GeVMiv-_yT2qO_iKyaM_bMGX7w&libraries=places"
    );
    document.head.appendChild(googleScript);
  },
};
</script>

<style scoped>
.create-cite {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  animation: appear2 0.35s;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  z-index: 1500;
  cursor: pointer;
}
@keyframes appear2 {
  0% {
    transform: scale(1.1);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.error-popup {
  padding-right: 10px;
  position: absolute;
  bottom: 30px;
  left: calc(50% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 350px;
  border-radius: 8px;
  background: white;
  animation: appear 0.3s;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.12);
  z-index: 10000;
}
.error-popup i {
  margin: 0 15px;
  font-size: 1.5rem;
}
.error-popup span {
  text-align: left;
  font-size: 0.9rem;
  font-weight: 500;
}
@keyframes appear {
  0% {
    transform: translateY(30px);
    box-shadow: none;
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
    box-shadow: 0 20px 20px rgba(0, 0, 0, 0.12);
  }
}
.create-cite .cite-form {
  position: relative;
  height: auto;
  width: 50%;
  max-height: 700px;
  background: white;
  border-radius: 8px;
  cursor: auto;
}
.cite-form .header-form {
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10%;
  min-height: 70px;
  border-bottom: 1px solid #eee;
}
.header-form span {
  font-size: 1.4rem;
  font-weight: bold;
}
.header-form .close-icon {
  font-size: 1.5rem;
  color: #191c22;
  cursor: pointer;
}
.cite-form .main-form {
  padding: 30px 5%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 5%;
  width: 100%;
  height: auto;
}
.main-form .main-left {
  grid-area: 1 / 1 / 2 / 2;
}
.main-form .main-right {
  grid-area: 1 / 2 / 2 / 3;
  display: flex;
  flex-direction: column;
}
.main-form .input-item {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
}
.input-item > span {
  margin-bottom: 5px;
  font-size: 1rem;
  font-weight: 500;
}
.input-item input:not([type="image"]) {
  padding: 0 5px;
  height: 45px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 8px;
  outline: none;
  background: transparent;
}
.input-item input::placeholder {
  font-size: 0.85rem;
}
.input-item textarea {
  margin-top: 5px;
  padding: 5px;
  width: 100%;
  height: 130px;
  border: 1px solid #eee;
  outline: none;
  border-radius: 8px;
}
.input-item textarea::placeholder {
  font-size: 0.85rem;
}
.cite-form .footer-form {
  padding: 0 5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10%;
  min-height: 70px;
  border-top: 1px solid #eee;
}
.entity-concern {
  display: flex;
  height: 50px;
  /* background: teal; */
}
.entity-concern label {
  margin-right: 20px;
  display: flex;
  align-items: center;
  height: auto;
  width: auto;
}
.entity-concern label span {
  margin-left: 5px;
}
.input-item select {
  padding: 0 5px;
  height: 45px;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 8px;
  outline: none;
  background: transparent;
}
</style>
